<template>
    <div class="project">
        <h1>Glimpse Twitter Visualization</h1>
        <div class="topInfo">
            <p>
                Event: Business Insider's Ignition 2013<br />
                Agency:
                <a href="https://wearebarbarian.com/">Barbarian Group</a> <br />
                Role: Programmer
            </p>
        </div>
        <div class="media">
            <b-embed
                class="media"
                type="iframe"
                aspect="16by9"
                allowfullscreen
                src="https://player.vimeo.com/video/108587432"
            ></b-embed>
        </div>
        <div class="content">
            <p>
                An interactive visualization of twitter relationships based on a hashtag, designed to initiate ideation
                for future client directions for any given topic. The tweets could be sorted in real-time by date,
                popularity, or sentiment. We also added a representation of sentiment based on color, as well as mapping
                the size to the popularity of each tweet.
            </p>
            <p>
                This was a an early experiment with Cinder's upcoming, redesigned OpenGL wrapper. There were elements
                that used simple models (.obj files in this case, for the cards), as well as procedural geometry (the
                expanding cylinders), along with the Twitter avatar images and text. For lighting, I used a combination
                of two key lights and an environment map.
            </p>
            <p>Built in C++ with <a href="https://libcinder.org/">libcinder</a>.</p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/projects/style.scss";
@import "../../assets/common.scss";
</style>